export default Object.freeze({
  getTeachers: {
    GET: () => {
      return 'attendances/attendances'
    }
  },
  addAttendance: {
    GET: 'attendances/attendance'
  },
  editAttendance: {
    GET: (id) => {
      return `attendances/${id}`
    }
  },
  updateAttendance: {
    GET: () => {
      return 'attendances/attendance'
    }
  },
  getAttendance: {
    GET: (id) => {
      return `attendances/${id}`
    }
  },
  deleteAttendance: {
    GET: (id) => {
      return `attendances/${id}`
    }
  },
  getAttendanceStatus: {
    GET: (id) => {
      return 'attendances/status'
    }
  }
})
