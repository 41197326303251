import lessonEndpoints from './endpoints/lesson-endpoints'
import apiConfig from '../config/api'
import http from './http'
// import store from "../store/index";

const {
  getLesson,
  addLesson,
  editLesson,
  deleteLesson,
  getLessonAttendance,
  getLessonOverview,
  getLessonCompleted,
  getLessonUnattended
} = lessonEndpoints
const { baseURL } = apiConfig

const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjRWVEhnbTVTUTltY25QVEVVQ0p1dyJ9.eyJpc3MiOiJodHRwczovL2Rldi10NTVwcTdieS5ldS5hdXRoMC5jb20vIiwic3ViIjoibHd3V3JaQ05TdDNLeGY5RGIzNjgzZmtUQk9SZ2J5M1JAY2xpZW50cyIsImF1ZCI6InByb2xpbmd1YSIsImlhdCI6MTY1Mjg4MjIxOCwiZXhwIjoxNjUyOTY4NjE4LCJhenAiOiJsd3dXclpDTlN0M0t4ZjlEYjM2ODNma1RCT1JnYnkzUiIsImd0eSI6ImNsaWVudC1jcmVkZW50aWFscyJ9.V512m-sZCtXkeetZgUFbWfTxzkXjakU7HvOrSACGOcK7Xpc8GKxK-MagebkqSKrfabHJe9imENDcCtxr1CPAFwrkOVXCNLEob4Gjxxs_pOJSv5Rg4yPXdhyi2QDhrVYjT2J4aRe4Dh_9mRnwzKwPyugn04u5ClIl4dBAkn7m9TyJlGgWyaEzYtup0qn0-KKqVFIF0HPRE4ai6rEjUsiCBlK9Gfzvuwua8eJOL4s8EJEkYUjVXdsCLxRVAoGQRypgc0wO0Nc-qTpVdpeS_wnWS8MfAjrUPFYFjzgZZar8Y6RhpANdi5YiryNwGtW2_Gqp7IKRxfW8R0uZeNdV1k39_g'

const LessonService = {
  async addLesson (lesson) {
    const getAddLessonUrl = `${baseURL}${addLesson.GET}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .post(
        getAddLessonUrl,
        {
          active: lesson.active,
          date: lesson.date,
          groupId: lesson.groupId,
          duration: lesson.duration
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async editLessonAsCompleted (lessonId) {
    const getEditLessonUrl = `${baseURL}${getLessonCompleted.GET(lessonId)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .put(
        getEditLessonUrl,
        { id: lessonId },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async editLessonAsUnattended (lessonId) {
    const getEditLessonUnattendedUrl = `${baseURL}${getLessonUnattended.GET(lessonId)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .put(
        getEditLessonUnattendedUrl,
        { id: lessonId },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async editLesson (lesson) {
    const getEditLessonUrl = `${baseURL}${editLesson.GET(lesson.id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .put(
        getEditLessonUrl,
        {
          id: lesson.id,
          date: lesson.date,
          groupId: lesson.groupId,
          duration: lesson.duration,
          active: lesson.active
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async getLesson (id) {
    const getLessonURL = `${baseURL}${getLesson.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getLessonURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async deleteLesson (id) {
    const getDeleteLessonUrl = `${baseURL}${deleteLesson.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .delete(
        getDeleteLessonUrl,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async getLessonAttendance (id) {
    const getLessonAttendanceURL = `${baseURL}${getLessonAttendance.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getLessonAttendanceURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async getLessonOverview (id) {
    const getLessonOverviewURL = `${baseURL}${getLessonOverview.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getLessonOverviewURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  }
}

export default LessonService
