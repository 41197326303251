<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
      <router-link
        slot="right"
        :to="heroRouterLinkTo"
        class="button is-primary"
      >
        {{ heroRouterLinkLabel }}
      </router-link>
    </hero-bar>
    <b-loading
      :is-full-page="true"
      :active="isLoading"
    />
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="account-edit"
          class="tile is-child"
        >
          <ValidationObserver ref="form">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Nume"
                    rules="required"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      label="Nume"
                      :message="errors[0] ? errors[0] : 'Nume client'"
                    >
                      <input
                        id="name"
                        v-model="customer.name"
                        name="name"
                        class="form-control editable_field"
                      >
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      label="Email"
                      :message="errors[0] ? errors[0] : 'Email client. Va fi folosit la autentificare'"
                    >
                      <b-input
                        id="email"
                        v-model="customer.email"
                        name="email"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <b-field grouped>
              <div class="control">
                <b-button
                  type="is-primary"
                  native-type="submit"
                  @click="save()"
                >
                  Salvare
                </b-button>
              </div>
              <div class="control">
                <b-button
                  type="is-danger is-outlined"
                  @click="cancel()"
                >
                  Anulare
                </b-button>
              </div>
            </b-field>
          </validationobserver>
        </card-component>
        <card-component
          v-if="ifCustomerExists"
          title="Module"
          icon="calendar"
          class="tile is-child"
        >
          <button
            class="button is-primary"
            @click="addModule()"
          >
            Modul nou
          </button>
          <modules-table-sample
            :modules="clientModules"
            @handleDeleteModule="handleDeleteModule"
          />
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>

import { useRouter } from '@/router'
import { reactive, computed, ref, watch } from '@vue/composition-api'
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import Tiles from '@/components/Tiles.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModulesTableSample from '@/components/ModulesTableSample.vue'
import ClientService from '@/services/clients.service'
import ModuleService from '@/services/modules.service'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'ClientForm',
  components: {
    Tiles,
    HeroBar,
    TitleBar,
    CardComponent,
    ModulesTableSample,
    ValidationProvider
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  setup (props, { root: { $buefy } }) {
    const ifCustomerExists = ref(false)
    const clientModules = ref([])
    const isLoading = ref(false)

    const titleStack = computed(() => ['Linguapro', 'Clienti', ifCustomerExists.value ? customer.name : 'Adaugare client'])

    const heroTitle = computed(() => ifCustomerExists.value ? 'Editare client' : 'Adaugare client')

    const heroRouterLinkTo = computed(() => ifCustomerExists.value ? { name: 'client.new' } : { name: 'home' })

    const heroRouterLinkLabel = computed(() => ifCustomerExists.value ? 'Client nou' : 'Centralizator cursuri LinguaproF')

    const formCardTitle = computed(() => ifCustomerExists.value ? customer.name : 'Client nou')

    const customer = reactive({
      id: null,
      name: null,
      email: null
    })

    const router = useRouter()

    const clientId = computed(() => props.id)

    isLoading.value = true

    const getData = async () => {
      if (clientId.value) {
        try {
          const { getCustomer } = ClientService
          const response = await getCustomer(clientId.value)
          if (response && response.data) {
            const existingCustomer = response.data
            ifCustomerExists.value = true
            customer.id = existingCustomer.id
            customer.name = existingCustomer.name
            customer.email = existingCustomer.email
          } else {
            router.push({ name: 'client.new' })
          }
        } catch (err) {
        }
      }

      isLoading.value = false
    }

    getData()

    async function getClientModules () {
      try {
        const { getClientModules } = ClientService
        await getClientModules(clientId.value).then((response) => {
          clientModules.value = response.data
        })
      } catch (err) {
      }

      isLoading.value = false
    }

    getClientModules()

    watch(clientId, newValue => {
      ifCustomerExists.value = false

      if (!newValue) {
        customer.id = null
        customer.name = null
        customer.email = null
      } else {
        getData()
      }
    })

    const formCustomer = computed(() => customer)

    const submit = () => {
      isLoading.value = true

      setTimeout(() => {
        isLoading.value = false

        $buefy.snackbar.open({
          message: 'Salvare in curs',
          queue: false
        })
      }, 750)
    }

    return {
      ifCustomerExists,
      titleStack,
      heroTitle,
      heroRouterLinkTo,
      heroRouterLinkLabel,
      formCardTitle,
      customer: formCustomer,
      clientModules,
      isLoading,
      submit
    }
  },
  methods: {
    async handleDeleteModule (id) {
      const { deleteModule } = ModuleService
      const response = await deleteModule(id)
      const { status } = response
      if (status === 200) {
        const index = this.clientModules.findIndex((prop) => prop.id === id)
        this.clientModules.splice(index, 1)
        this.$buefy.snackbar.open({
          message: 'Inregistrea a fost stearsa.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la stergerea inregistrarii.',
          queue: false
        })
      }
    },
    addModule () {
      this.$router.push({ name: 'module.newClientId', params: { clientId: this.id } })
    },
    save () {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return
        }
        if (this.ifCustomerExists) {
          this.handleEditCustomer(this.customer)
        } else {
          this.handleAddCustomer(this.customer)
        }
      })
    },
    cancel () {
      this.$router.push({ path: '/clients' })
    },
    async handleAddCustomer (customer) {
      const { addCustomer } = ClientService
      const response = await addCustomer(customer)
      const { status } = response
      if (status === 200) {
        this.$buefy.snackbar.open({
          message: 'Inregistrarea a fost adaugata.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la adaugarea inregistrarii',
          queue: false
        })
      }
      this.$router.push({
        path: '/clients'
      })
    },
    async handleEditCustomer (customer) {
      const { editClient } = ClientService
      const response = await editClient(customer)
      const { status } = response
      if (status === 200) {
        this.$buefy.snackbar.open({
          message: 'Inregistrarea a fost editata.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la modificarea inregistrarii',
          queue: false
        })
      }
      this.$router.push({
        path: '/clients'
      })
    }
  }
}
</script>
