import attendanceEndpoints from './endpoints/attendance-endpoints'
import apiConfig from '../config/api'
import http from './http'
// import store from "../store/index";

const {
  getAttendannces,
  getAttendance,
  addAttendance,
  editAttendance,
  updateAttendance,
  deleteAttendance,
  getAttendanceStatus
} = attendanceEndpoints
const { baseURL } = apiConfig

const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjRWVEhnbTVTUTltY25QVEVVQ0p1dyJ9.eyJpc3MiOiJodHRwczovL2Rldi10NTVwcTdieS5ldS5hdXRoMC5jb20vIiwic3ViIjoibHd3V3JaQ05TdDNLeGY5RGIzNjgzZmtUQk9SZ2J5M1JAY2xpZW50cyIsImF1ZCI6InByb2xpbmd1YSIsImlhdCI6MTY1Mjg4MjIxOCwiZXhwIjoxNjUyOTY4NjE4LCJhenAiOiJsd3dXclpDTlN0M0t4ZjlEYjM2ODNma1RCT1JnYnkzUiIsImd0eSI6ImNsaWVudC1jcmVkZW50aWFscyJ9.V512m-sZCtXkeetZgUFbWfTxzkXjakU7HvOrSACGOcK7Xpc8GKxK-MagebkqSKrfabHJe9imENDcCtxr1CPAFwrkOVXCNLEob4Gjxxs_pOJSv5Rg4yPXdhyi2QDhrVYjT2J4aRe4Dh_9mRnwzKwPyugn04u5ClIl4dBAkn7m9TyJlGgWyaEzYtup0qn0-KKqVFIF0HPRE4ai6rEjUsiCBlK9Gfzvuwua8eJOL4s8EJEkYUjVXdsCLxRVAoGQRypgc0wO0Nc-qTpVdpeS_wnWS8MfAjrUPFYFjzgZZar8Y6RhpANdi5YiryNwGtW2_Gqp7IKRxfW8R0uZeNdV1k39_g'

const AttendanceService = {
  async getAttendances () {
    const getAttendancesURL = `${baseURL}${getAttendannces.GET()}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getAttendancesURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async addAttendance (attendance) {
    const getAddClientUrl = `${baseURL}${addAttendance.GET}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .post(
        getAddClientUrl,
        {
          studentId: attendance.studentId,
          date: attendance.date,
          statusId: attendance.statusId
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async editAttendance (attendance) {
    const getEditAttendanceUrl = `${baseURL}${editAttendance.GET(attendance.id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .put(
        getEditAttendanceUrl,
        {
          id: attendance.id,
          studentId: attendance.studentId,
          date: attendance.date,
          statusId: attendance.statusId
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async updateAttendance (attendance) {
    const getUpdateAttendanceUrl = `${baseURL}${updateAttendance.GET()}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .put(
        getUpdateAttendanceUrl,
        {
          id: attendance.id,
          statusId: attendance.statusId
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async getAttendance (id) {
    const getAttendanceURL = `${baseURL}${getAttendance.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getAttendanceURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async deleteAttendance (id) {
    const getDeleteAttendanceUrl = `${baseURL}${deleteAttendance.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .delete(
        getDeleteAttendanceUrl,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async getAttendanceStatus () {
    const getAttendanceStatusURL = `${baseURL}${getAttendanceStatus.GET()}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getAttendanceStatusURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  }
}

export default AttendanceService
