<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
      <router-link
        slot="right"
        :to="heroRouterLinkTo"
        class="button is-primary"
      >
        {{ heroRouterLinkLabel }}
      </router-link>
    </hero-bar>
    <b-loading
      :is-full-page="true"
      :active="isLoading"
    />
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="human-male-board"
          class="tile is-child"
        >
          <ValidationObserver ref="form">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Nume"
                    rules="required"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      label="Nume"
                      :message="errors[0] ? errors[0] : 'Nume profesor'"
                    >
                      <b-input
                        id="lastName"
                        v-model="teacher.lastName"
                        name="lastName"
                        class="form-control editable_field"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Prenume"
                    rules="required"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      label="Prenume"
                      :message="errors[0] ? errors[0] : 'Prenume profesor'"
                    >
                      <b-input
                        id="firstName"
                        v-model="teacher.firstName"
                        name="firstName"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      label="Email"
                      :message="errors[0] ? errors[0] : 'Email profesor. Va fi folosit la autentificare'"
                    >
                      <b-input
                        id="email"
                        v-model="teacher.email"
                        name="email"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <b-field grouped>
              <div class="control">
                <b-button
                  type="is-primary"
                  native-type="submit"
                  @click="save()"
                >
                  Salvare
                </b-button>
              </div>
              <div class="control">
                <b-button
                  type="is-danger is-outlined"
                  @click="cancel()"
                >
                  Anulare
                </b-button>
              </div>
            </b-field>
          </ValidationObserver>
        </card-component>
        <card-component
          v-if="ifTeacherExists"
          title="Grupe"
          icon="table"
          class="tile is-child"
        >
          <hr>
          <group-table-sample :groups="teacherGroupList" />
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import { useRouter } from '@/router'
import { reactive, computed, ref, watch } from '@vue/composition-api'
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import Tiles from '@/components/Tiles.vue'
import CardComponent from '@/components/CardComponent.vue'
import GroupTableSample from '@/components/GroupsTableSample.vue'
import TeacherService from '@/services/teachers.service'
import GroupService from '@/services/groups.service'

export default {
  name: 'TeacherForm',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar,
    GroupTableSample
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  setup (props, { root: { $buefy } }) {
    const ifTeacherExists = ref(false)
    const teacherGroupList = ref([])

    const titleStack = computed(() => ['Linguapro', 'Profesori', ifTeacherExists.value ? teacher.lastName + ' ' + teacher.firstName : 'Adaugare profesor'])

    const heroTitle = computed(() => ifTeacherExists.value ? 'Editare profesor' : 'Adaugare profesor')

    const heroRouterLinkTo = computed(() => ifTeacherExists.value ? { name: 'teacher.new' } : { name: 'home' })

    const heroRouterLinkLabel = computed(() => ifTeacherExists.value ? 'Profesor nou' : 'Centralizator cursuri Linguapro')

    const formCardTitle = computed(() => ifTeacherExists.value ? teacher.lastName + ' ' + teacher.firstName : 'Profesor nou')

    const teacher = reactive({
      id: null,
      firstName: null,
      lastName: null,
      email: null
    })

    const createdReadable = ref(null)

    const isLoading = ref(false)

    const router = useRouter()

    const teacherId = computed(() => props.id)

    async function getTeacherGroups () {
      try {
        const { getTeacherGroups } = GroupService
        await getTeacherGroups(teacherId.value).then((response) => {
          teacherGroupList.value = response.data
        })
      } catch (err) {
      }
    }

    isLoading.value = true

    getTeacherGroups()

    const getData = async () => {
      if (teacherId.value) {
        try {
          const { getTeacher } = TeacherService
          const response = await getTeacher(teacherId.value)
          if (response && response.data) {
            const existingTeacher = response.data
            ifTeacherExists.value = true
            teacher.id = existingTeacher.id
            teacher.firstName = existingTeacher.firstName
            teacher.lastName = existingTeacher.lastName
            teacher.email = existingTeacher.email
            isLoading.value = false
          } else {
            router.push({ name: 'teacher.new' })
          }
        } catch (err) {
        }
      }
      isLoading.value = false
    }

    getData()

    watch(teacherId, newValue => {
      ifTeacherExists.value = false

      if (!newValue) {
        teacher.id = null
        teacher.firstName = null
        teacher.lastName = null
        teacher.email = null
      } else {
        getData()
      }
    })

    const dateInput = v => {
      createdReadable.value = new Date(v).toLocaleDateString()
    }

    const submit = () => {
      isLoading.value = true

      setTimeout(() => {
        isLoading.value = false

        $buefy.snackbar.open({
          message: 'Salvare in curs',
          queue: false
        })
      }, 750)
    }

    return {
      ifTeacherExists,
      titleStack,
      heroTitle,
      heroRouterLinkTo,
      heroRouterLinkLabel,
      formCardTitle,
      teacher,
      teacherGroupList,
      createdReadable,
      dateInput,
      isLoading,
      submit
    }
  },
  methods: {
    save () {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return
        }
        if (this.ifTeacherExists) {
          this.handleEditTeacher(this.teacher)
        } else {
          this.handleAddTeacher(this.teacher)
        }
      })
    },
    cancel () {
      this.$router.push({ path: '/teachers' })
    },
    async handleAddTeacher (teacher) {
      const { addTeacher } = TeacherService
      const response = await addTeacher(teacher)
      const { status } = response
      if (status === 200) {
        this.$buefy.snackbar.open({
          message: 'Inregistrarea a fost adaugata.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la adaugarea inregistrarii',
          queue: false
        })
      }
      this.$router.push({
        path: '/teachers'
      })
    },
    async handleEditTeacher (teacher) {
      const { editTeacher } = TeacherService
      const response = await editTeacher(teacher)
      const { status } = response
      if (status === 200) {
        this.$buefy.snackbar.open({
          message: 'Inregistrarea a fost editata.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la modificarea inregistrarii',
          queue: false
        })
      }
      this.$router.push({
        path: '/teachers'
      })
    }
  }
}
</script>
