var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('modal-box',{attrs:{"is-active":_vm.isModalActive,"trash-object-name":_vm.trashObject ? _vm.trashObject.name : null},on:{"confirm":_vm.trashConfirm,"cancel":_vm.trashCancel}}),(_vm.lessons.length > 0)?_c('b-table',{attrs:{"checked-rows":_vm.checkedRows,"checkable":_vm.checkable,"per-page":_vm.perPage,"data":_vm.lessons,"default-sort":"name","striped":"","hoverable":""},on:{"update:checkedRows":function($event){_vm.checkedRows=$event},"update:checked-rows":function($event){_vm.checkedRows=$event}}},[_c('b-table-column',{attrs:{"label":"Numar","field":"order","sortable":""},scopedSlots:_vm._u([{key:"subheading",fn:function(){return [_vm._v(" "+_vm._s(_vm.nrLessons)+" lectii (total) ")]},proxy:true},{key:"default",fn:function(props){return [_c('span',{class:[
              'tag',
              {'is-danger': props.row.active == false},
              {'is-success': props.row.active == true}
            ]},[_vm._v(" "+_vm._s(props.row.order == 0 ? '-' : props.row.order)+" ")])]}}],null,false,3764587084)}),_c('b-table-column',{attrs:{"label":"Data","field":"date","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm._f("formatDateRoDetail")(props.row.date))+" ")]}}],null,false,547886827)}),_c('b-table-column',{attrs:{"label":"Desfasurata","field":"date","centered":""},scopedSlots:_vm._u([{key:"subheading",fn:function(){return [_vm._v(" "+_vm._s(_vm.nrCompletedLessons)+" lectii (total) ")]},proxy:true},{key:"default",fn:function(props){return [_c('span',{class:[
              'tag',
              {'is-danger': props.row.active == false},
              {'is-success': props.row.active == true}
            ]},[_vm._v(" "+_vm._s(props.row.active ? 'DA' : 'NU')+" ")])]}}],null,false,1915508586)}),_c('b-table-column',{attrs:{"label":"Durata","field":"duration"},scopedSlots:_vm._u([{key:"subheading",fn:function(){return [_vm._v(" "+_vm._s(_vm.hours)+" ore (total) ")]},proxy:true},{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.duration)+" ore ")]}}],null,false,4251782726)}),(_vm.isCoordinator || _vm.isTeacher)?_c('b-table-column',{attrs:{"custom-key":"actions","cell-class":"is-actions-cell"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"buttons is-right no-wrap"},[_c('router-link',{staticClass:"button is-small is-primary",attrs:{"to":{name:'lesson.edit', params: {id: props.row.id}}}},[_c('b-icon',{attrs:{"icon":"account-edit","size":"is-small"}})],1),_c('b-button',{attrs:{"native-type":"button","type":"is-danger","size":"is-small"},on:{"click":function($event){$event.preventDefault();return _vm.trashModalOpen(props.row)}}},[_c('b-icon',{attrs:{"icon":"trash-can","size":"is-small"}})],1)],1)]}}],null,false,2172085786)}):_vm._e()],1):_vm._e(),(_vm.lessons.length ==0)?_c('section',{staticClass:"section",attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"emoticon-sad","size":"is-large"}})],1),_c('p',[_vm._v("Nu exista inregistrari…")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }