<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObject ? trashObject.name : null "
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <b-table
      v-if="lessons.length > 0"
      :checked-rows.sync="checkedRows"
      :checkable="checkable"
      :per-page="perPage"
      :data="lessons"
      default-sort="name"
      striped
      hoverable
    >
      <b-table-column
        label="Numar"
        field="order"
        sortable
      >
        <template #subheading>
          {{ nrLessons }}  lectii (total)
        </template>
        <template #default="props">
          <span
            :class="
              [
                'tag',
                {'is-danger': props.row.active == false},
                {'is-success': props.row.active == true}
              ]"
          >
            {{ props.row.order == 0 ? '-' : props.row.order }}
          </span>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="props"
        label="Data"
        field="date"
        sortable
      >
        {{ props.row.date | formatDateRoDetail }}
      </b-table-column>
      <b-table-column
        label="Desfasurata"
        field="date"
        centered
      >
        <template #subheading>
          {{ nrCompletedLessons }}  lectii (total)
        </template>
        <template #default="props">
          <span
            :class="
              [
                'tag',
                {'is-danger': props.row.active == false},
                {'is-success': props.row.active == true}
              ]"
          >
            {{ props.row.active ? 'DA' : 'NU' }}
          </span>
        </template>
      </b-table-column>
      <b-table-column
        label="Durata"
        field="duration"
      >
        <template #subheading>
          {{ hours }} ore (total)
        </template>
        <template #default="props">
          {{ props.row.duration }} ore
        </template>
      </b-table-column>
      <b-table-column
        v-if="isCoordinator || isTeacher"
        v-slot="props"
        custom-key="actions"
        cell-class="is-actions-cell"
      >
        <div class="buttons is-right no-wrap">
          <router-link
            :to="{name:'lesson.edit', params: {id: props.row.id}}"
            class="button is-small is-primary"
          >
            <b-icon
              icon="account-edit"
              size="is-small"
            />
          </router-link>
          <b-button
            native-type="button"
            type="is-danger"
            size="is-small"
            @click.prevent="trashModalOpen(props.row)"
          >
            <b-icon
              icon="trash-can"
              size="is-small"
            />
          </b-button>
        </div>
      </b-table-column>
    </b-table>
    <section
      v-if="lessons.length ==0"
      slot="empty"
      class="section"
    >
      <div class="content has-text-grey has-text-centered">
        <p>
          <b-icon
            icon="emoticon-sad"
            size="is-large"
          />
        </p>
        <p>Nu exista inregistrari&hellip;</p>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useStore } from '@/store'
import ModalBox from '@/components/ModalBox.vue'

export default {
  name: 'LessonsTableSample',
  components: { ModalBox },
  props: {
    checkable: Boolean,
    isEmpty: Boolean,
    lessons: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { root: { $buefy }, emit }) {
    const store = useStore()

    const perPage = ref(10)

    const checkedRows = ref([])

    const isModalActive = ref(false)

    const trashObject = ref(null)

    const numberOfLessons = ref({})

    const trashModalOpen = obj => {
      trashObject.value = obj
      isModalActive.value = true
    }

    const trashConfirm = () => {
      isModalActive.value = false
      emit('handleDeleteLesson', trashObject.value.id)
    }

    const trashCancel = () => {
      isModalActive.value = false
    }
    return {
      perPage,
      // paginated,
      checkedRows,
      isModalActive,
      trashObject,
      trashModalOpen,
      trashConfirm,
      trashCancel,
      numberOfLessons,
      lessons_local: [],
      isCoordinator: store.state.isCoordinator,
      isClient: store.state.isClient,
      isTeacher: store.state.isTeacher
    }
  },
  computed: {
    nrLessons () {
      return this.lessons.length
    },
    nrCompletedLessons () {
      return this.lessons.filter((lesson) => { return lesson.active }).length
      //       collection.filter((element) => {
      //   return element.age > 30;
      // }).sort((a, b) => {
      //   return a.age - b.age;
      // });
    },
    hours () {
      var hours = 0
      for (var i = 0; i < this.lessons.length; i++) {
        if (this.lessons[i].active) {
          hours += this.lessons[i].duration
        }
      }
      return hours
    }
  }
}
</script>
