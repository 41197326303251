<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
      <router-link
        slot="right"
        :to="heroRouterLinkTo"
        class="button is-primary"
      >
        {{ heroRouterLinkLabel }}
      </router-link>
    </hero-bar>
    <b-loading
      :is-full-page="true"
      :active="isLoading"
    />
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="calendar"
          class="tile is-child"
        >
          <ValidationObserver ref="form">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Nume"
                    rules="required"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      label="Nume"
                      :message="errors[0] ? errors[0] : 'Nume modul'"
                    >
                      <b-input
                        id="name"
                        v-model="mod.name"
                        name="name"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Client"
                    rules="required"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      label="Client"
                      :message="errors[0] ? errors[0] : 'Numele clientului pentru care va fi creat modulul'"
                    >
                      <b-select
                        id="client"
                        v-model="mod.customerId"
                        name="client"
                        placeholder="Selectati un client"
                        expanded
                      >
                        <option
                          v-for="(client, index) in clientList"
                          :key="index"
                          :value="client.id"
                        >
                          {{ client.name }}
                        </option>
                      </b-select>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Numar de ore"
                    rules="required|numeric"
                  >
                    <b-field
                      id="numberOfHours"
                      :type="errors[0] ? 'is-danger' : ''"
                      label="Ore"
                      name="numberOfHours"
                      :message="errors[0] ? errors[0] : 'Numarul de ore al modulului'"
                    >
                      <b-input
                        v-model="mod.numberOfHours"
                        required
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field
                  label="Data inceput"
                  message="Data de inceput a modulului"
                >
                  <b-datepicker
                    v-model="mod.startDate"
                    locale="ro"
                    horizontal-time-picker
                  />
                </b-field>
              </div>
              <div class="column">
                <b-field
                  label="Data sfarsit"
                  message="Data de sfarsit a modulului"
                >
                  <b-datepicker
                    v-model="mod.endDate"
                    locale="ro"
                    horizontal-time-picker
                  />
                </b-field>
              </div>
            </div>
            <b-field grouped>
              <div class="control">
                <b-button
                  type="is-primary"
                  native-type="submit"
                  @click="save()"
                >
                  Salvare
                </b-button>
              </div>
              <div class="control">
                <b-button
                  type="is-danger is-outlined"
                  @click="cancel()"
                >
                  Anulare
                </b-button>
              </div>
            </b-field>
          </ValidationObserver>
        </card-component>
        <card-component
          v-if="ifModuleExists"
          title="Grupe"
          icon="account-group"
          class="tile is-child"
        >
          <button
            class="button is-primary"
            @click="addGroup()"
          >
            Grupa noua
          </button>
          <groups-table-sample
            :groups="moduleGroups"
            @handleDeleteGroup="handleDeleteGroup"
          />
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import { useRouter } from '@/router'
import { reactive, computed, ref, watch } from '@vue/composition-api'
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import Tiles from '@/components/Tiles.vue'
import CardComponent from '@/components/CardComponent.vue'
import GroupsTableSample from '@/components/GroupsTableSample.vue'
import ModuleService from '@/services/modules.service'
import ClientService from '@/services/clients.service'
import GroupService from '@/services/groups.service'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'ModuleForm',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar,
    GroupsTableSample,
    ValidationProvider
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    },
    clientId: {
      type: [String, Number],
      default: null
    }
  },
  setup (props, { root: { $buefy } }) {
    const ifModuleExists = ref(false)
    const clientList = ref([])
    const moduleGroups = ref([])
    const titleStack = computed(() => ['Linguapro', 'Module', ifModuleExists.value ? mod.name : 'Adaugare modul'])
    const isLoading = ref(false)

    isLoading.value = true

    async function getClients () {
      try {
        const { getClients } = ClientService
        await getClients().then((response) => {
          clientList.value = response.data
        })
      } catch (err) {
      }
    }

    getClients()

    const heroTitle = computed(() => ifModuleExists.value ? 'Editare modul' : moduleClient.name ? `Modul nou pentru clientul ${moduleClient.name}` : 'Adaugare modul')

    const heroRouterLinkTo = computed(() => ifModuleExists.value ? { name: 'module.new' } : { name: 'home' })

    const heroRouterLinkLabel = computed(() => ifModuleExists.value ? 'Modul nou' : 'Centralizator cursuri Linguapro')

    const formCardTitle = computed(() => ifModuleExists.value ? mod.name : 'Modul nou')

    const mod = reactive({
      id: null,
      name: null,
      startDate: new Date(),
      endDate: new Date(),
      numberOfHours: null,
      customerId: null
    })

    const moduleClient = reactive({
      id: null,
      name: null
    })

    const createdReadable = ref(null)

    const router = useRouter()

    const moduleId = computed(() => props.id)

    const clientId = computed(() => props.clientId)

    if (clientId.value) {
      mod.customerId = clientId
    }

    const getData = async () => {
      if (moduleId.value) {
        try {
          const { getModule } = ModuleService
          const response = await getModule(moduleId.value)
          if (response && response.data) {
            const existingModule = response.data
            ifModuleExists.value = true
            mod.id = existingModule.id
            mod.name = existingModule.name
            mod.numberOfHours = existingModule.numberOfHours
            mod.customerId = existingModule.customerId
            mod.startDate = new Date(existingModule.startDate)
            mod.endDate = new Date(existingModule.endDate)
          } else {
            router.push({ name: 'module.new' })
          }
        } catch (err) {
        }
      }
    }

    isLoading.value = true
    getData()

    const getClientData = async (clientId) => {
      const { getCustomer } = ClientService
      const response = await getCustomer(clientId)
      if (response && response.data) {
        const client = response.data
        moduleClient.id = client.id
        moduleClient.name = client.name
      }
    }

    getClientData(clientId.value)

    async function getModuleGroups () {
      try {
        const { getModuleGroups } = ModuleService
        await getModuleGroups(moduleId.value).then((response) => {
          moduleGroups.value = response.data
          isLoading.value = false
        })
      } catch (err) {
      }
    }

    if (clientId.value) {
      getClientData(clientId.value)
    }

    getModuleGroups()

    watch(moduleId, newValue => {
      ifModuleExists.value = false

      if (!newValue) {
        mod.id = null
        mod.name = null
        mod.startDate = null
        mod.endDate = null
        mod.numberOfHours = null
        mod.customerId = null
      } else {
        getData()
      }
    })

    const submit = () => {
      isLoading.value = true

      setTimeout(() => {
        isLoading.value = false

        $buefy.snackbar.open({
          message: 'Salvare in curs',
          queue: false
        })
      }, 750)
    }

    return {
      ifModuleExists,
      titleStack,
      heroTitle,
      heroRouterLinkTo,
      heroRouterLinkLabel,
      formCardTitle,
      mod,
      clientList,
      moduleGroups,
      createdReadable,
      isLoading,
      submit
    }
  },
  methods: {
    async handleDeleteGroup (id) {
      const { deleteGroup } = GroupService
      const response = await deleteGroup(id)
      const { status } = response
      if (status === 200) {
        const index = this.moduleGroups.findIndex((prop) => prop.id === id)
        this.moduleGroups.splice(index, 1)
        this.$buefy.snackbar.open({
          message: 'Inregistrea a fost stearsa.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la stergerea inregistrarii.',
          queue: false
        })
      }
    },
    addGroup () {
      this.$router.push({ name: 'group.newModuleId', params: { moduleId: this.id } })
    },
    save () {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return
        }
        if (this.ifModuleExists) {
          this.handleEditModule(this.mod)
        } else {
          this.handleAddModule(this.mod)
        }
      })
    },
    async handleAddModule (newModule) {
      const { addModule } = ModuleService
      const response = await addModule(newModule)
      const { status } = response
      if (status === 200) {
        this.$buefy.snackbar.open({
          message: 'Inregistrarea a fost adaugata.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la adaugarea inregistrarii',
          queue: false
        })
      }
      this.$router.back()
    },
    async handleEditModule (module) {
      const { editModule } = ModuleService
      const response = await editModule(module)
      const { status } = response
      if (status === 200) {
        this.$buefy.snackbar.open({
          message: 'Inregistrarea a fost editata.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la modificarea inregistrarii',
          queue: false
        })
      } this.$router.back()
    },
    cancel () {
      this.$router.back()
    }
  }
}
</script>
