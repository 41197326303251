<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
      <router-link
        slot="right"
        :to="heroRouterLinkTo"
        class="button is-primary"
      >
        {{ heroRouterLinkLabel }}
      </router-link>
    </hero-bar>
    <b-loading
      :is-full-page="true"
      :active="isLoading"
    />
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="book-open"
          class="tile is-child"
        >
          <div class="columns">
            <div class="column">
              <b-field
                label="Status"
              >
                <div class="block">
                  <b-checkbox
                    v-model="lesson.active"
                    @input="changeLessonStatus()"
                  >
                    Desfasurata
                  </b-checkbox>
                </div>
              </b-field>
            </div>
            <div class="column">
              <b-field
                label="Data"
                message="Data sedintei"
              >
                <b-datepicker
                  v-model="lesson.date"
                  locale="ro"
                  horizontal-time-picker
                />
              </b-field>
            </div>
            <div class="column">
              <b-field
                label="Durata (ore)"
                message="Durata in ore a sedintei"
              >
                <b-input
                  v-model="lesson.duration"
                />
              </b-field>
            </div>
          </div>
          <div class="columns">
            <b-field grouped>
              <div class="control">
                <b-button
                  type="is-primary"
                  native-type="submit"
                  @click="save()"
                >
                  Salvare
                </b-button>
              </div>
              <div class="control">
                <b-button
                  type="is-danger is-outlined"
                  @click="cancel()"
                >
                  Anulare
                </b-button>
              </div>
            </b-field>
          </div>
        </card-component>
        <card-component
          title="Prezente"
          class="tile is-child"
          hr
        >
          <div>
            <b-editable-table
              v-model="lessonAttendance"
              bordered
              responsive
              scrollable="both"
              class="editable-table full-width"
              style="width: 100%;"
              :fields="catalogfields"
              @input-change="handleInput"
            />
          </div>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import { useRouter } from '@/router'
import { reactive, computed, ref } from '@vue/composition-api'
import BEditableTable from 'bootstrap-vue-editable-table'
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import Tiles from '@/components/Tiles.vue'
import CardComponent from '@/components/CardComponent.vue'
import LessonService from '@/services/lessons.service'
import * as moment from 'moment/moment'
import GroupService from '@/services/groups.service'
import AttendanceService from '@/services/attendances.service'
moment.locale('ro')

export default {
  name: 'LessonForm',
  components: {
    BEditableTable,
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    },
    groupId: {
      type: [String, Number],
      default: null
    }
  },
  setup (props, { root: { $buefy } }) {
    const ifLessonExists = ref(false)
    const groupList = ref([])
    const studentAttendances = ref([])

    const titleStack = computed(() => ['Linguapro', 'Lectii', ifLessonExists.value ? moment(lesson.date).format('dddd, Do MMMM, YYYY') : 'Adaugare sedinta'])

    const heroTitle = computed(() => ifLessonExists.value ? 'Editare sedinta' : lessonGroup.name ? `Sedinta noua pentru grupa ${lessonGroup.name}` : 'Adaugare sedinta')

    const heroRouterLinkTo = computed(() => ifLessonExists.value ? { name: 'lesson.new' } : { name: 'home' })

    const heroRouterLinkLabel = computed(() => ifLessonExists.value ? 'Adaugare sedinta' : 'Centralizator cursuri Linguapro')

    const formCardTitle = computed(() => ifLessonExists.value ? moment(lesson.date).format('dddd, Do MMMM, YYYY') : 'Sedinta noua')

    const isLoading = ref(false)

    const lesson = reactive({
      id: null,
      date: new Date(),
      groupId: null,
      active: false
    })

    const lessonGroup = reactive({
      id: null,
      name: null
    })

    const createdReadable = ref(null)

    const router = useRouter()

    const lessonId = computed(() => props.id)

    const groupId = computed(() => props.groupId)

    const getData = async () => {
      if (lessonId.value) {
        try {
          const { getLesson } = LessonService
          const response = await getLesson(lessonId.value)
          if (response && response.data) {
            const existingLesson = response.data
            ifLessonExists.value = true
            lesson.id = existingLesson.id
            lesson.groupId = existingLesson.groupId
            lesson.active = existingLesson.active
            lesson.duration = existingLesson.duration
            lesson.date = new Date(existingLesson.date)
          } else {
            router.push({ name: 'lesson.new' })
          }
        } catch (err) {
        }
      }
    }

    const getGroupData = async (groupId) => {
      const { getGroup } = GroupService
      const response = await getGroup(groupId)
      if (response && response.data) {
        const group = response.data
        lessonGroup.id = group.id
        lessonGroup.name = group.name
      } else {
        router.push({ name: 'lesson.new' })
      }
    }

    isLoading.value = true
    getData()

    if (groupId.value) {
      getGroupData(groupId.value)
    }

    const dateInput = v => {
      createdReadable.value = new Date(v).toLocaleDateString()
    }

    const submit = () => {
      isLoading.value = true

      setTimeout(() => {
        isLoading.value = false

        $buefy.snackbar.open({
          message: 'Salvare in curs',
          queue: false
        })
      }, 750)
    }

    return {
      lessonId,
      ifLessonExists,
      titleStack,
      heroTitle,
      heroRouterLinkTo,
      heroRouterLinkLabel,
      formCardTitle,
      lesson,
      lessonGroup,
      groupList,
      studentAttendances,
      createdReadable,
      dateInput,
      isLoading,
      lessonAttendance: ref([]),
      submit,
      catalogfields: []
    }
  },
  async created () {
    await this.getAttendanceStatus()
    await this.getLessonAttendance(this.lessonId)
    this.isLoading = false
  },
  methods: {
    save () {
      if (this.ifLessonExists) {
        this.handleEditLesson(this.lesson)
      } else {
        this.lesson.groupId = this.groupId
        this.handleAddLesson(this.lesson)
      }
    },
    async changeLessonStatus () {
      if (this.lesson.active) {
        this.$buefy.dialog.confirm({
          title: 'Modificare status sedinta',
          message: `Setati aceasta lectie ca fiind desfasurata? Prezentele vor fi adaugate automat cu durata implicita a lectiei (${this.lesson.duration} ore). Se pot modifica dupa caz.`,
          onConfirm: async () => {
            this.markLessonAsCompleted(this.lesson.id).then(async () => {
              await this.getLessonAttendance(this.lessonId)
            })
          },
          onCancel () {
          }
        })
      } else {
        this.$buefy.dialog.confirm({
          title: 'Modificare status sedinta',
          message: 'Setati aceasta lectie ca fiind nedesfasurata? Prezentele vor fi adaugate automat cu statusul Nedesfasurat.',
          onConfirm: async () => {
            this.markLessonAsUnattended(this.lesson.id).then(async () => {
              await this.getLessonAttendance(this.lessonId)
            })
            // await this.getAttendanceStatus()
          },
          onCancel () {
          }
        })
      }
    },
    handleInput (evt) {
      const attendance = {
        date: evt.item.date,
        studentId: evt.item.studentId,
        statusId: evt.value,
        id: evt.id
      }
      this.handleEditAttendance(attendance).then(() => { this.getLessonAttendance(this.lessonId) })
    },
    cancel () {
      this.$router.back()
    },
    async handleAddLesson (lesson) {
      const { addLesson } = LessonService
      const response = await addLesson(lesson)
      const { status } = response
      if (status === 200) {
        this.$buefy.snackbar.open({
          message: 'Inregistrarea a fost adaugata.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la adaugarea inregistrarii',
          queue: false
        })
      }
      this.$router.back()
    },
    async handleEditLesson (lesson) {
      const { editLesson } = LessonService
      const response = await editLesson(lesson)
      const { status } = response
      if (status === 200) {
        this.$buefy.snackbar.open({
          message: 'Inregistrarea a fost editata.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la modificarea inregistrarii',
          queue: false
        })
      }
      this.$router.back()
    },
    async getAttendanceStatus () {
      try {
        const { getAttendanceStatus } = AttendanceService
        await getAttendanceStatus().then((response) => {
          this.statusOptions = response.data.map((attendance) => {
            return {
              id: attendance.id,
              value: attendance.value,
              text: attendance.description
            }
          })
        })
      } catch (err) {
      }
    },
    async handleEditAttendance (attendance) {
      const { updateAttendance } = AttendanceService
      const response = await updateAttendance(attendance)
      const { status } = response
      if (status === 200) {
        this.$buefy.snackbar.open({
          message: 'Inregistrarea a fost editata.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la modificarea inregistrarii',
          queue: false
        })
      }
    },
    async markLessonAsCompleted (lessonId) {
      const { editLessonAsCompleted } = LessonService
      const response = await editLessonAsCompleted(lessonId)
      const { status } = response
      if (status === 200) {
        this.$buefy.snackbar.open({
          message: 'Inregistrarea a fost editata.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la modificarea inregistrarii',
          queue: false
        })
      }
    },
    async markLessonAsUnattended (lessonId) {
      const { editLessonAsUnattended } = LessonService
      const response = await editLessonAsUnattended(lessonId)
      const { status } = response
      if (status === 200) {
        this.$buefy.snackbar.open({
          message: 'Inregistrarea a fost editata.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la modificarea inregistrarii',
          queue: false
        })
      }
    },
    async getLessonAttendance (lessonId) {
      try {
        const { getLessonAttendance } = LessonService
        await getLessonAttendance(lessonId).then((response) => {
          this.lessonAttendance = response.data
          this.catalogfields = [
            {
              key: 'studentName',
              label: 'Cursant'
            },
            {
              key: 'statusValue',
              label: 'Prezenta',
              type: 'select',
              editable: this.lesson.active,
              class: 'department-col',
              options: this.statusOptions
            }
          ]
        })
      } catch (err) {
      }
    }
  }
}
</script>
