export default Object.freeze({
  addLesson: {
    GET: 'lessons/lesson'
  },
  editLesson: {
    GET: (id) => {
      return `lessons/${id}`
    }
  },
  getLessonCompleted: {
    GET: (id) => {
      return `lessons/${id}/completed`
    }
  },
  getLessonUnattended: {
    GET: (id) => {
      return `lessons/${id}/unattended`
    }
  },
  getLesson: {
    GET: (id) => {
      return `lessons/${id}`
    }
  },
  getLessonAttendance: {
    GET: (id) => {
      return `lessons/${id}/attendance`
    }
  },
  getLessonOverview: {
    GET: (id) => {
      return `lessons/${id}/lessonattendancesoverview`
    }
  },
  deleteLesson: {
    GET: (id) => {
      return `lessons/${id}`
    }
  }
})
