<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
      <router-link
        slot="right"
        :to="heroRouterLinkTo"
        class="button is-primary"
      >
        {{ heroRouterLinkLabel }}
      </router-link>
    </hero-bar>
    <b-loading
      :is-full-page="true"
      :active="isLoading"
    />
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="account-school"
          class="tile is-child"
        >
          <ValidationObserver ref="form">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Nume"
                    rules="required"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      :message="errors[0] ? errors[0] : 'Nume cursant'"
                      label="Nume"
                    >
                      <b-input
                        id="lastName"
                        v-model="student.lastName"
                        name="lastName"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Prenume"
                    rules="required"
                  >
                    <b-field
                      label="Prenume"
                      :type="errors[0] ? 'is-danger' : ''"
                      :message="errors[0] ? errors[0] : 'Prenume cursant'"
                    >
                      <b-input
                        v-model="student.firstName"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column is-6">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Grupa"
                    rules="required"
                  >
                    <b-field
                      label="Grupa"
                      :type="errors[0] ? 'is-danger' : ''"
                      :message="errors[0] ? errors[0] : 'Grupa in care va fi atribuit cursantul'"
                    >
                      <b-select
                        v-model="student.groupId"
                        placeholder="Selectati o grupa"
                        expanded
                      >
                        <option
                          v-for="(group, index) in groupList"
                          :key="index"
                          :value="group.id"
                        >
                          {{ group.name }}
                        </option>
                      </b-select>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <b-field grouped>
              <div class="control">
                <b-button
                  type="is-primary"
                  native-type="submit"
                  @click="save()"
                >
                  Salvare
                </b-button>
              </div>
              <div class="control">
                <b-button
                  type="is-danger is-outlined"
                  @click="cancel()"
                >
                  Anulare
                </b-button>
              </div>
            </b-field>
          </ValidationObserver>
        </card-component>
        <!-- <card-component
          title="Prezente"
          icon="table"
          class="tile is-child"
        >
          <div>
            <router-link
              :to="{name:'attendance.new', params: { studentId: studentId } }"
              class="button is-primary"
            >
              Prezenta noua
            </router-link>
          </div>
        </card-component> -->
      </tiles>
      <!-- <student-attendance-table :id="id" /> -->
    </section>
  </div>
</template>

<script>
import { useRouter } from '@/router'
import { reactive, computed, ref, watch } from '@vue/composition-api'
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import Tiles from '@/components/Tiles.vue'
import CardComponent from '@/components/CardComponent.vue'
// import StudentAttendanceTable from '@/components/StudentsAttendanceTable.vue'
import StudentService from '@/services/students.service'
import GroupService from '@/services/groups.service'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'StudentForm',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar,
    ValidationProvider
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    },
    groupId: {
      type: [String, Number],
      default: null
    }
  },
  setup (props, { root: { $buefy } }) {
    const ifStudentExists = ref(false)
    const groupList = ref([])
    const studentAttendances = ref([])
    const isLoading = ref(false)

    const titleStack = computed(() => ['Linguapro', 'Cursanti', ifStudentExists.value ? student.lastName + ' ' + student.firstName : 'Adaugare cursant'])

    async function getGroups () {
      try {
        const { getGroups } = GroupService
        await getGroups().then((response) => {
          groupList.value = response.data
        })
      } catch (err) {
      }
    }

    isLoading.value = true

    getGroups()

    const heroTitle = computed(() => ifStudentExists.value ? 'Editare cursant' : studentGroup.name ? `Cursant nou pentru grupa ${studentGroup.name}` : 'Adaugare cursant')

    const heroRouterLinkTo = computed(() => ifStudentExists.value ? { name: 'student.new' } : { name: 'home' })

    const heroRouterLinkLabel = computed(() => ifStudentExists.value ? 'Cursant nou' : 'Centralizator cursuri Linguapro')

    const formCardTitle = computed(() => ifStudentExists.value ? student.lastName + ' ' + student.firstName : 'Cursant nou')

    const student = reactive({
      id: null,
      firstName: null,
      lastName: null,
      groupId: null
    })

    const studentGroup = reactive({
      id: null,
      name: null
    })

    const createdReadable = ref(null)

    const router = useRouter()

    const studentId = computed(() => props.id)

    const groupId = computed(() => props.groupId)

    const studentGroupId = computed(() => props.groupId)
    if (studentGroupId.value) {
      student.groupId = studentGroupId.value
    }

    const getData = async () => {
      if (studentId.value) {
        try {
          const { getStudent } = StudentService
          const response = await getStudent(studentId.value)
          if (response && response.data) {
            const existingStudent = response.data
            ifStudentExists.value = true
            student.id = existingStudent.id
            student.firstName = existingStudent.firstName
            student.lastName = existingStudent.lastName
            student.groupId = existingStudent.groupId
          } else {
            router.push({ name: 'client.new' })
          }
        } catch (err) {
        }
      }
    }

    const getGroupData = async (groupId) => {
      const { getGroup } = GroupService
      const response = await getGroup(groupId)
      if (response && response.data) {
        const group = response.data
        studentGroup.id = group.id
        studentGroup.name = group.name
      }
    }

    isLoading.value = true

    getData()

    async function getStudentAttendances () {
      try {
        const { getStudentAttendances } = StudentService
        await getStudentAttendances(studentId.value).then((response) => {
          studentAttendances.value = response.data

          isLoading.value = false
        })
      } catch (err) {
      }
    }

    if (groupId.value) {
      getGroupData(groupId.value)
    }

    getStudentAttendances()

    watch(studentId, newValue => {
      ifStudentExists.value = false

      if (!newValue) {
        student.id = null
        student.firstName = null
        student.lastName = null
        student.groupId = null
      } else {
        getData()
      }
    })

    const dateInput = v => {
      createdReadable.value = new Date(v).toLocaleDateString()
    }

    const submit = () => {
      isLoading.value = true

      setTimeout(() => {
        isLoading.value = false

        $buefy.snackbar.open({
          message: 'Salvare in curs',
          queue: false
        })
      }, 750)
    }

    return {
      ifStudentExists,
      titleStack,
      heroTitle,
      heroRouterLinkTo,
      heroRouterLinkLabel,
      formCardTitle,
      student,
      studentGroup,
      groupList,
      studentAttendances,
      createdReadable,
      dateInput,
      isLoading,
      submit,
      studentId
    }
  },
  methods: {
    save () {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return
        }
        if (this.ifStudentExists) {
          this.handleEditStudent(this.student)
        } else {
          this.handleAddStudent(this.student)
        }
      })
    },
    cancel () {
      this.$router.back()
    },
    async handleAddStudent (student) {
      const { addStudent } = StudentService
      const response = await addStudent(student)
      const { status } = response
      if (status === 200) {
        this.$buefy.snackbar.open({
          message: 'Inregistrarea a fost adaugata.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la adaugarea inregistrarii',
          queue: false
        })
      }
      this.$router.back()
    },
    async handleEditStudent (student) {
      const { editStudent } = StudentService
      const response = await editStudent(student)
      const { status } = response
      if (status === 200) {
        this.$buefy.snackbar.open({
          message: 'Inregistrarea a fost editata.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la modificarea inregistrarii',
          queue: false
        })
      }
      this.$router.back()
    }
  }
}
</script>
