<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="table"
          class="tile is-child"
        >
          <div class="columns">
            <div class="column">
              <b-field
                label="Data"
              >
                <b-datepicker
                  v-model="attendance.date"
                  locale="ro"
                  horizontal-time-picker
                />
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Status">
                <b-select
                  v-model="attendance.statusId"
                  required
                  expanded
                >
                  <option
                    v-for="(status, index) in attendanceStatusList"
                    :key="index"
                    :value="status.id"
                  >
                    {{ status.description }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
          <hr>
          <b-field grouped>
            <div class="control">
              <b-button
                type="is-primary"
                native-type="submit"
                @click="save()"
              >
                Salvare
              </b-button>
            </div>
            <div class="control">
              <b-button
                type="is-danger is-outlined"
                @click="cancel()"
              >
                Anulare
              </b-button>
            </div>
          </b-field>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>

import { useRouter } from '@/router'
import { reactive, computed, ref, watch } from '@vue/composition-api'
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import Tiles from '@/components/Tiles.vue'
import CardComponent from '@/components/CardComponent.vue'
import AttendanceService from '@/services/attendances.service'

export default {
  name: 'AttendanceForm',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    },
    studentId: {
      type: [String, Number],
      default: null
    }
  },
  setup (props, { root: { $buefy } }) {
    const ifAttendanceExists = ref(false)

    const attendanceStatusList = ref([])

    const titleStack = computed(() => ['Linguapro', 'Prezente', ifAttendanceExists.value ? attendance.date.toLocaleDateString() : 'Prezenta noua'])

    const heroTitle = computed(() => ifAttendanceExists.value ? attendance.name : 'Prezenta noua')

    const heroRouterLinkTo = computed(() => ifAttendanceExists.value ? { name: 'attendance.new' } : { name: 'home' })

    const heroRouterLinkLabel = computed(() => ifAttendanceExists.value ? 'Prezenta nou' : 'Centralizator cursuri Linguapro')

    const formCardTitle = computed(() => ifAttendanceExists.value ? 'Editare prezenta' : 'Prezenta noua')

    const attendance = reactive({
      id: null,
      date: new Date(),
      studentId: null,
      statusId: null
    })

    async function getAttendanceStatus () {
      try {
        const { getAttendanceStatus } = AttendanceService
        await getAttendanceStatus().then((response) => {
          attendanceStatusList.value = response.data
        })
      } catch (err) {
      }
    }

    getAttendanceStatus()

    const createdReadable = ref(null)

    const router = useRouter()

    const attendanceId = computed(() => props.id)

    const getData = async () => {
      if (attendanceId.value) {
        try {
          const { getAttendance } = AttendanceService
          const response = await getAttendance(attendanceId.value)
          if (response && response.data) {
            const existingAttendance = response.data
            ifAttendanceExists.value = true
            attendance.id = existingAttendance.id
            attendance.date = new Date(existingAttendance.date)
            attendance.studentId = existingAttendance.studentId
            attendance.statusId = existingAttendance.statusId
          } else {
            router.push({ name: 'attendance.new' })
          }
        } catch (err) {
        }
      }
    }

    getData()

    watch(attendanceId, newValue => {
      ifAttendanceExists.value = false

      if (!newValue) {
        attendance.id = null
        attendance.date = null
        attendance.studentId = null
        attendance.statusId = null
      } else {
        getData()
      }
    })

    const isLoading = ref(false)

    const submit = () => {
      isLoading.value = true

      setTimeout(() => {
        isLoading.value = false

        $buefy.snackbar.open({
          message: 'Salvare in curs',
          queue: false
        })
      }, 750)
    }

    return {
      ifAttendanceExists,
      titleStack,
      heroTitle,
      heroRouterLinkTo,
      heroRouterLinkLabel,
      formCardTitle,
      attendance,
      createdReadable,
      isLoading,
      submit,
      attendanceStatusList
    }
  },
  methods: {
    save () {
      if (this.ifAttendanceExists) {
        this.handleEditAttendance(this.attendance)
      } else {
        this.attendance.studentId = this.studentId
        this.handleAddAttendance(this.attendance)
      }
    },
    cancel () {
      this.$router.push({ path: '/attendance' })
    },
    async handleAddAttendance (attendance) {
      const { addAttendance } = AttendanceService
      const response = await addAttendance(attendance)
      const { status } = response
      if (status === 200) {
        this.$buefy.snackbar.open({
          message: 'Inregistrarea a fost adaugata.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la adaugarea inregistrarii',
          queue: false
        })
      }
      this.$router.push({
        path: '/attendance'
      })
    },
    async handleEditAttendance (attendance) {
      const { editAttendance } = AttendanceService
      const response = await editAttendance(attendance)
      const { status } = response
      if (status === 200) {
        this.$buefy.snackbar.open({
          message: 'Inregistrarea a fost editata.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la modificarea inregistrarii',
          queue: false
        })
      }
      this.$router.push({
        path: '/attendance'
      })
    }
  }
}
</script>
