<template>
  <div>
    <title-bar
      v-if="currentGroup"
      :title-stack="titleStack"
      :subtitle="subtitle"
    />
    <b-loading
      :is-full-page="true"
      :active="loading"
    />
    <section class="section is-main-section">
      <card-component
        title="Prezente"
        class="tile is-child"
        hr
      >
        <card-toolbar
          v-if="isCoordinator || isTeacher"
          slot="toolbar"
        >
          <div
            slot="left"
            class="buttons has-addons"
          >
            <b-dropdown aria-role="list">
              <template #trigger="{ active }">
                <b-button
                  label="Raport detaliat prezente"
                  type="is-primary"
                  :icon-right="active ? 'menu-up' : 'menu-down'"
                />
              </template>
              <b-dropdown-item
                aria-role="listitem"
                @click="downloadDetailedReport()"
              >
                Descarca
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </card-toolbar>
        <b-editable-table
          v-model="usersList"
          :busy="loading"
          bordered
          responsive
          scrollable="both"
          class="editable-table"
          :fields="fields"
          @input-change="handleInput"
        >
          <template #table-busy>
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle" />
              <strong>Incarcare date prezente</strong>
            </div>
          </template>
        </b-editable-table>
      </card-component>
      <tiles>
        <card-component
          title="Calendar lectii"
          class="tile is-child"
          hr
        >
          <card-toolbar
            v-if="isCoordinator || isTeacher"
            slot="toolbar"
          >
            <div
              slot="left"
              class="buttons has-addons"
            >
              <router-link
                v-if="groupLessons"
                :to="{name:'lesson.new', params: { groupId: groupId } }"
                class="button is-primary"
              >
                Adauga sedinta
              </router-link>
            </div>
          </card-toolbar>
          <lessons-table-sample
            :lessons="groupLessons"
            @handleDeleteLesson="handleDeleteLesson"
          />
        </card-component>
      </tiles>
    </section>
  </div>
</template>
<script>

import TitleBar from '@/components/TitleBar.vue'
import { computed, ref } from '@vue/composition-api'
import { useStore } from '@/store'
import CardComponent from '@/components/CardComponent.vue'
import CardToolbar from '@/components/CardToolbar.vue'
import LessonsTableSample from '@/components/LessonsTableSample.vue'
import Tiles from '@/components/Tiles.vue'
import BEditableTable from 'bootstrap-vue-editable-table'
import GroupService from '@/services/groups.service'
import AttendanceService from '@/services/attendances.service'
import LessonService from '@/services/lessons.service'
import * as moment from 'moment/moment'
// import downloadexcel from 'vue-json-excel'
import * as XLSX from 'xlsx'

export default ({
  name: 'GroupAttendance',
  components: { TitleBar, CardComponent, CardToolbar, LessonsTableSample, Tiles, BEditableTable },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  setup (props) {
    const store = useStore()

    const groupId = computed(() => props.id)
    const groupLessons = ref([])
    const currentGroup = ref({})

    const groupLessonsWithoutAttendance = ref([])
    const lessonAttendance = ref([])
    const lessonId = ref({})

    async function getGroupLessons (id) {
      try {
        const { getGroupLessons } = GroupService
        await getGroupLessons(id).then((response) => {
          groupLessons.value = response.data
        })
      } catch (err) {
      } finally {
      }
    }

    getGroupLessons(groupId.value)
    async function getGroupDetails () {
      try {
        const { getGroup } = GroupService
        const response = await getGroup(groupId.value)
        currentGroup.value = response.data
      } catch (err) {
      }
    }

    getGroupDetails()

    const titleStack = computed(() => ['Linguapro', `${currentGroup.value.name}, ${currentGroup.value.teacherFullName}, ${currentGroup.value.level}`])
    const subtitle = computed(() => `${currentGroup.value.moduleName}, ${moment(String(currentGroup.value.moduleStartDate)).format('DD/MM/YYYY')} - ${moment(String(currentGroup.value.moduleEndDate)).format('DD/MM/YYYY')}`)

    return {
      currentGroup,
      titleStack,
      subtitle,
      groupLessons,
      groupLessonsWithoutAttendance,
      groupId,
      loading: false,
      loaded: false,
      groupLessonsLoaded: false,
      showAttendace: false,
      lessonAttendance,
      lessonId,
      attendanceForUsersTable: [],
      attendanceStatusList: [],
      statusOptions: [],
      usersList: [],
      fields: [],
      isCoordinator: store.state.isCoordinator,
      isClient: store.state.isClient,
      isTeacher: store.state.isTeacher
    }
  },
  async created () {
    await this.getAttendanceStatus()
    await this.init(this.groupId)
  },
  methods: {
    downloadDetailedReport () {
      const wb = XLSX.utils.book_new()
      const data = this.usersList.map(user => {
        const dynamicFields = {}
        for (const [key, value] of Object.entries(user.dates)) {
          let statusValue
          switch (value.status) {
            case 0:
              statusValue = 'Nedesfășurat'
              break
            case -1:
              statusValue = 'Pers.'
              break
            case -2:
              statusValue = 'Serviciu'
              break
            case -3:
              statusValue = 'CO'
              break
            case -4:
              statusValue = 'Absent'
              break
            default:
              statusValue = value.status
          }
          dynamicFields[key] = statusValue
        }
        return {
          Nume: user.name,
          'Ore cursant': user.hours,
          '% cursant': user.attendacePercentage,
          'Ore grupa': user.totalHours,
          ...dynamicFields // add dynamic cus tom fields
        }
      })
      const headers = ['Nume', 'Ore cursant', '% cursant', 'Ore grupa', ...Object.keys(this.usersList[0].dates)] // add dynamic headers
      const ws = XLSX.utils.json_to_sheet(data, { header: headers })
      // Set values for dynamic columns
      data.forEach((row, rowIndex) => {
        Object.entries(row).forEach(([columnKey, value]) => {
          if (!headers.includes(columnKey)) {
            const cellRef = XLSX.utils.encode_cell({ r: rowIndex + 1, c: headers.indexOf(columnKey) })
            const cell = ws[cellRef]
            cell.s = { alignment: { horizontal: 'center' } } // Set cell alignment to center
          }
        })
      })

      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, 'Raport_saptamanal.xlsx')
    },
    convertDatesToString (dates) {
      const result = {}
      for (const [key, value] of Object.entries(dates)) {
        result[key] = {}
        for (const [innerKey, innerValue] of Object.entries(value)) {
          if (innerKey === 'id' || innerKey === 'active') {
            result[key][innerKey] = innerValue
          } else {
            result[key][innerKey] = innerValue.toString()
          }
        }
      }
      return result
    },
    handleInput (evt) {
      const attendance = {
        date: evt.field.key,
        studentId: evt.id,
        statusId: evt.value,
        id: evt.item.dates[evt.field.key].id
      }
      this.handleEditAttendance(attendance).then(() => {
        this.init(this.groupId)
        this.getGroupLessons(this.groupId)
      })
    },
    async handleDeleteLesson (id) {
      const { deleteLesson } = LessonService
      const response = await deleteLesson(id)
      const { status } = response
      if (status === 200) {
        const index = this.groupLessons.findIndex((prop) => prop.id === id)
        this.groupLessons.splice(index, 1)
        this.$buefy.snackbar.open({
          message: 'Inregistrea a fost stearsa.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la stergerea inregistrarii.',
          queue: false
        })
      }
      this.init(this.groupId)
    },
    async getAttendanceStatus () {
      try {
        const { getAttendanceStatus } = AttendanceService
        await getAttendanceStatus().then((response) => {
          this.attendanceStatusList.value = response.data
          this.statusOptions = this.attendanceStatusList.value.map((attendance) => {
            return {
              value: attendance.value,
              text: attendance.description
            }
          })
        })
      } catch (err) {
      }
    },
    async init (groupId) {
      this.loading = true

      try {
        const { getGroupAttendance } = GroupService
        const response = await getGroupAttendance(groupId)
        const data = response.data
        this.attendanceForUsersTable = data

        this.attendanceForUsersTable = this.attendanceForUsersTable.sort((a, b) => new Date(a.date) - new Date(b.date))

        this.loading = false
        const clonedArray = JSON.parse(JSON.stringify(this.attendanceForUsersTable))
        clonedArray.forEach((item, index) => {
          for (const [key, value] of Object.entries(item.dates)) {
            item[key] = value.status
          }
        })
        this.usersList = clonedArray

        const result = [
          {
            attendanceId: 0,
            id: 0,
            key: 'name',
            label: 'Nume',
            type: 'text',
            editable: false
          },
          {
            key: 'hours',
            label: 'Ore cursant',
            type: 'text',
            editable: false,
            class: 'attendance-stats'
          },
          {
            key: 'attendacePercentage',
            label: '% cursant',
            type: 'text',
            editable: false,
            class: 'attendance-stats'
          },
          {
            key: 'totalHours',
            label: 'Ore grupa',
            type: 'text',
            editable: false,
            class: 'attendance-stats'
          }
        ]

        this.attendanceForUsersTable.forEach((student, index) => {
          Object.keys(this.attendanceForUsersTable[index].dates).forEach(el => {
            result.push({
              attendanceId: student,
              id: student.dates[el].id,
              key: el,
              label: student.dates[el].id,
              type: 'select',
              editable: this.isTeacher | this.isCoordinator,
              class: student.dates[el].active ? 'department-col' : 'department-col-inactive',
              options: this.statusOptions
            })
          })
        })

        this.fields = result
      } catch (err) {
        this.$buefy.snackbar.open({
          message: 'Eroarare la incarcarea prezentelor',
          type: 'is-danger',
          pauseOnHover: true,
          queue: false
        })
        this.loading = false
      }
    },
    async handleEditAttendance (attendance) {
      this.loading = true
      const { updateAttendance } = AttendanceService
      const response = await updateAttendance(attendance)
      const { status } = response
      if (status === 200) {
        this.$buefy.snackbar.open({
          message: 'Inregistrarea a fost editata.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la modificarea inregistrarii',
          queue: false
        })
      }
    },
    async getGroupLessons (id) {
      try {
        const { getGroupLessons } = GroupService
        await getGroupLessons(id).then((response) => {
          this.groupLessons = response.data
        })
      } catch (err) {
      } finally {
      }
    }
  }

})
</script>

<style lang="scss" scoped>
::v-deep .editable-table {
  width: 100%;
  overflow-x: auto;
  position: relative;
}

::v-deep .editable-table thead th:first-child,
::v-deep .editable-table tbody td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #fff;
}

::v-deep .editable-table tbody td:first-child {
  background-color: #f8f9fa;
}
::v-deep .editable-table {
  width: 100%;
  overflow-x: auto;

  th {
    min-width: 20px;
  }

  td {
    vertical-align: middle;
    padding: 0px;
  }

  .department-col {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .department-col-inactive {
    color: red;
  }

  .attendance-stats {
    color: green;
  }

  .data-cell {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-around;
  }

  .fixed-width-header {
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: #fff;
    width: 350px; /* Adjust the width as per your requirement */
  }
}

::v-deep .attendance-filters {
  @media screen and (max-width: 768px) {
    select {
      min-width: 200px;
    }
  }
}
</style>
